import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import {
  useTableDispatch,
  ActionType,
} from '@components/TableV2/TableProvider/TableProvider'

export const SettingsButton = () => {
  const dispatch = useTableDispatch()
  return (
    <MoreBar.Action
      useIcon="Pause"
      onClick={() => dispatch({ type: ActionType.OpenSettings })}
    >
      Column settings
    </MoreBar.Action>
  )
}
